import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { THorseBreed, THorseBreedInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: THorseBreedInitialStateType = {
    HorseBreedData: [],
    HorseBreedLoading: true,
    HorseBreedError: undefined,
};


export const GetAllHorseBreed = createAsyncThunk(
    "customer-info/HorseBreed/getHorseBreed",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.horseBreed();
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const HorseBreedSlice = createSlice({
    name: 'customer-info/HorseBreed/getAllHorseBreed',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseBreed.pending, (state) => {
                state.HorseBreedLoading = true;
            })
            .addCase(GetAllHorseBreed.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HorseBreedLoading = false;
                    state.HorseBreedData = action.payload as THorseBreed[];
                }
            })
            .addCase(GetAllHorseBreed.rejected, (state, action) => {
                state.HorseBreedLoading = false;
            });
    },
});


export default HorseBreedSlice.reducer;




