import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { THorseLocation, THorseLocationInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: THorseLocationInitialStateType = {
    HorseLocationData: [],
    HorseLocationLoading: true,
    HorseLocationError: undefined,
};


export const GetAllHorseLocation = createAsyncThunk(
    "customer-info/HorseLocation/getHorseLocation",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.horseLocation();
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const HorseLocationSlice = createSlice({
    name: 'customer-info/HorseLocation/getAllHorseLocation',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseLocation.pending, (state) => {
                state.HorseLocationLoading = true;
            })
            .addCase(GetAllHorseLocation.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HorseLocationLoading = false;
                    state.HorseLocationData = action.payload as THorseLocation[];
                }
            })
            .addCase(GetAllHorseLocation.rejected, (state, action) => {
                state.HorseLocationLoading = false;
            });
    },
});


export default HorseLocationSlice.reducer;




