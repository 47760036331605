import http from "../../../http-common";

export class ReportsAdminService{
  getCheckUserAdminStatus(customerID: string) {
    return http.get(`/Admin/CheckUserAdminStatus?customerID=${customerID}`);
  }
  getAdmin(customerID: string, pageNumber: number, pageSize: number,searchText:string) {
    return http.get(`/Admin/Admin?customerID=${customerID}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`);
  }
  getApproveReport(kitID: string) {
    return http.get(`/Report/ApproveReport?kitID=${kitID}`);
  }
  getSaveNotes(kitID: string, notes: string) {
    return http.patch(`/Report/SaveNotes?kitID=${kitID}`, {"notes": notes});
  }
}

export default new ReportsAdminService();
