import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
// Images
import { retrieveNavigationFooter } from "../redux/slice/NavigationSlice";
import { useAppDispatch, useTypedSelector, RootState } from "../redux/store";
import { TPageConfigurations } from "../models/PageConfigurationsModel";
import { TNavigation } from "../models/NavigationModel";
import { retrievePageConfigurations } from "../redux/slice/PageConfigurationsSlice";

const Footer = () => {
  const dispatch = useAppDispatch();
  // Navigation API Selector
  const { NavigationFooterData } = useTypedSelector(
    (state: RootState) => state.Navigation
  );
  // PageConfigurations  API Selector
  const { PageConfigurationsData } = useTypedSelector(
    (state: RootState) => state.PageConfigurations
  );

  const date = new Date();
  const currentYear = date.getFullYear();

  // dispatch load useEffect to call API's
  useEffect(() => {
    dispatch(retrieveNavigationFooter());
    dispatch(retrievePageConfigurations());
  }, [dispatch]);

  return (
    <footer className="footer">
      <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={6} lg={7} className="order-2 order-md-1">
            {PageConfigurationsData &&
              PageConfigurationsData.map(
                (menuItem: TPageConfigurations, index: number) => {
                  return (
                    <Link to="/" key={index}>
                      <img
                        alt={menuItem.footerLogoAltText}
                        src={menuItem.footerLogo}
                        className="footer-logo img-fluid"
                        width="146"
                        height="54"
                      />
                    </Link>
                  );
                }
              )}
            <p className="footer-text">
              © {currentYear} Purina Animal Nutrition LLC. All Rights Reserved.
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} className="order-1 order-md-2">
            <Nav
              fill
              defaultActiveKey="/home"
              as="ul"
              className="footer-nav justify-content-end"
            >
              {NavigationFooterData &&
                NavigationFooterData.map(
                  (menuItem: TNavigation, menuIndex: number) => {
                    return (
                      <Nav.Item as="li" key={menuIndex}>
                        <Nav.Link href={menuItem.redirectURL}>
                          {menuItem.title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  }
                )}
            </Nav>
            {PageConfigurationsData &&
              PageConfigurationsData.map(
                (menuItem: TPageConfigurations, index: number) => {
                  return (
                    <Nav
                      defaultActiveKey="/home"
                      as="ul"
                      className="footer-links"
                      key={index}
                    >
                      {menuItem.myPersonalInformationUrl && (
                        <Nav.Item as="li">
                          <Nav.Link
                            href={menuItem.myPersonalInformationUrl}
                            target={menuItem.myPersonalInformationUrlTarget}
                          >
                            {menuItem.myPersonalInformationText}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {menuItem.smsTermsUrl && (
                        <Nav.Item as="li">
                          <Nav.Link
                            href={menuItem.smsTermsUrl}
                            eventKey="link-1"
                            target={menuItem.smsTermsUrlTarget}
                          >
                            {menuItem.smsTerms}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {menuItem.privacyTermsUrl && (
                        <Nav.Item as="li">
                          <Nav.Link
                            href={menuItem.privacyTermsUrl}
                            eventKey="link-2"
                            target={menuItem.privacyTermsUrlTarget}
                          >
                            {menuItem.privacyTerms}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {menuItem.caSupplyChainUrl && (
                        <Nav.Item as="li">
                          <Nav.Link
                            href={menuItem.caSupplyChainUrl}
                            eventKey="link-3"
                            target={menuItem.caSupplyChainUrlTarget}
                          >
                            {menuItem.caSupplyChain}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                  );
                }
              )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
