import http from "../../http-common";
import { TAddHorse, TAssignKitHorse, TUpdatHorse } from "../../models/dashboard/MyHorsesModel";

class DashboardService {
  GetAllHorsesService(customerId: number, page: number) {
    return http.get(`/Dashboard/Horse?customerID=${customerId}&pageNumber=${page}`);
  }
  AdsCard(path: string) {
    return http.get(`/Dashboard/Cards?path=${path}`);
  }
  horseBreed() {
    return http.get(`/Dashboard/HorseBreed`);
  }
  horseAge() {
    return http.get(`/Dashboard/HorseAge`);
  }
  horseGender() {
    return http.get(`/Dashboard/HorseGender`);
  }
  horseLocation() {
    return http.get(`/Dashboard/HorseLocation`);
  }
  EditHorse(horseID: string) {
    return http.get(`/Dashboard/EditHorse?horseID=${horseID}`);
  }
  CreateHorse(horseInfo: TAddHorse) {
    return http.post(`/Dashboard/AddHorse`, horseInfo, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }});
  }

  updateHorse(horseInfo: TUpdatHorse) {
    return http.put(`/Dashboard/UpdateHorse`, horseInfo, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }});
  }

  assignKit(kitInfo: TAssignKitHorse) {
    return http.patch(`/Dashboard/AssignKit`, kitInfo);
  }

  horseKitInfoByID(horseID: string, currentPage: number, pageSize: number) {
    return http.get(`/Dashboard/HorseKitInfoByID?horseID=${horseID}&page=${currentPage}&pageSize=${pageSize}`);
  }
  SampleMailed(kitID: string, isSample: boolean) {
    return http.patch(`/Dashboard/SampleMailed?kitID=${kitID}&sample=${isSample}`);
  }
}

export default new DashboardService();