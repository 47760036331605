import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TPurinaTeam, TPurinaTeamInitialStateType } from '../../../models/home/PurinaTeamModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: TPurinaTeamInitialStateType = {
    PurinaTeamData: [],
    PurinaTeamLoading: true,
    PurinaTeamError: undefined,
  };

export const retrievePurinaTeam = createAsyncThunk(
    "home/PurinaTeam/retrieve",
    async () => {
      const response = await HomePageServces.getAllPurinaTeamService
      return [response.data];
    }
  );

const PurinaTeamSlice = createSlice({
  name: 'PurinaTeam',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePurinaTeam.pending, (state) => {
        state.PurinaTeamLoading = true;
      })
      .addCase(retrievePurinaTeam.fulfilled, (state, action) => {
        if(action.payload){
          state.PurinaTeamLoading = false;
          state.PurinaTeamData = action.payload as TPurinaTeam[];
        }
      })
      .addCase(retrievePurinaTeam.rejected, (state, action) => {
        state.PurinaTeamLoading = false;
      });
  },
});


export default PurinaTeamSlice.reducer;