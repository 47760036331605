import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.scss";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import DefaultLayout from "./containers/DefaultLayout";
import PageLoader from "./components/re-usable/PageLoader";
import { HelmetProvider } from "react-helmet-async";
import { loginRequest } from "./authConfig";

type TApp = {
  instance: IPublicClientApplication;
};

const App = ({ instance }: TApp): JSX.Element => {
  return (
    <>
      <HelmetProvider>
        <MsalProvider instance={instance}>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </MsalProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
