import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { TNavigation, TNavigationInitialStateType } from '../../models/NavigationModel';
import { TCartItem } from '../../pages/productDetail/ProductKitInfo';
import   { NavigationServces }   from '../../services/NavigationService';


const initialState: TNavigationInitialStateType = {
    NavigationHeaderData: [],
    NavigationFooterData: [],
    NavigationLoading: true,
    NavigationError: undefined,
  };

export const retrieveNavigationHeader = createAsyncThunk(
    "home/Navigation/header/retrieve",
    async () => {
      const response = await NavigationServces.getAllNavigationHeader('header')
      return response.data;
    }
  );

  export const retrieveNavigationFooter = createAsyncThunk(
    "home/Navigation/footer/retrieve",
    async () => {
      const response = await NavigationServces.getAllNavigationHeader('footer')
      return response.data;
    }
  );

const NavigationSlice = createSlice({
  name: 'Navigation',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNavigationHeader.pending, (state) => {
        state.NavigationLoading = true;
      })
      .addCase(retrieveNavigationHeader.fulfilled, (state, action) => {
        if(action.payload){
          state.NavigationLoading = false;
          state.NavigationHeaderData = action.payload as TNavigation[];
        }
      })
      .addCase(retrieveNavigationHeader.rejected, (state, action) => {
        state.NavigationLoading = false;
      })

      .addCase(retrieveNavigationFooter.pending, (state) => {
        state.NavigationLoading = true;
      })
      .addCase(retrieveNavigationFooter.fulfilled, (state, action) => {
        if(action.payload){
          state.NavigationLoading = false;
          state.NavigationFooterData = action.payload as TNavigation[];
        }
      })
      .addCase(retrieveNavigationFooter.rejected, (state, action) => {
        state.NavigationLoading = false;
      });
  },
});


export default NavigationSlice.reducer;


// Cart

export const collectCartItemsFromCookies = createAsyncThunk(
  'CartValues/collectFromCookies',
  async (_, { dispatch }) => {
    try {
      // Fetch cart items from cookies
      const cartItemsFromCookies = Cookies.get('cartItems') || '[]';
      
      // Parse the JSON string
      const parsedCartItems = JSON.parse(cartItemsFromCookies);
      
      // Dispatch the setCartValues action to update the Redux state
      dispatch(setCartValues({ CartItems: parsedCartItems }));
    } catch (error) {
      console.error('Error retrieving cart items from cookies:', error);
    }
  }
);

const CartInitialState: {CartItems: TCartItem[]} = {
    CartItems : []
  }

const CartValuesSlice = createSlice({
  name: 'CartValues',
  initialState: CartInitialState,
  reducers: {
    setCartValues: (state, action) => {            
      state.CartItems = action.payload.CartItems;
    },
  },
});

export const { setCartValues } = CartValuesSlice.actions;
export const CartValuesReducer = CartValuesSlice.reducer;
