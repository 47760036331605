import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THorseHealthSurvey, THorseHealthSurveyInitialStateType, THorseHealthSurveyResponse} from '../../../models/healthSurvey/HealthSurveyModel';
import HealthSurveyService from '../../../services/healthSurvey/HealthSurveyService';


const initialState: THorseHealthSurveyInitialStateType = {
    GetHorseHealthSurveyData: [],
    GetHorseHealthSurveyLoading: true,
    GetHorseHealthSurveyError: undefined,
};


export const AddHorseHealthSurvey = createAsyncThunk(
    "customer-info/HorseHealthSurvey/addHorseHealthSurvey",
    async (surveyInfor: THorseHealthSurvey, { rejectWithValue }) => {
        try {
            const response = await HealthSurveyService.addHorseHealthSurvey(surveyInfor)
            const { data, status } = response
            return [{data, status}];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const AddHorseHealthSurveySlice = createSlice({
    name: 'customer-info/HorseHealthSurvey/getAllHorseHealthSurvey',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(AddHorseHealthSurvey.pending, (state) => {
                state.GetHorseHealthSurveyLoading = true;
            })
            .addCase(AddHorseHealthSurvey.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetHorseHealthSurveyLoading = false;
                    state.GetHorseHealthSurveyData = action.payload as THorseHealthSurveyResponse[];
                }
            })
            .addCase(AddHorseHealthSurvey.rejected, (state, action) => {
                state.GetHorseHealthSurveyLoading = false;
            });
    },
});


export default AddHorseHealthSurveySlice.reducer;




