import axios from "axios";

const API_URL = process.env.REACT_APP_LOL_API_URL;
const shopify_URL = process.env.REACT_APP_SHOPIFY_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const ShopifyInstance = axios.create({
  baseURL: shopify_URL,
  headers: {
    "Content-type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
  } 
})

// Function to get the access token from localStorage
const getAccessToken = () => {
  const msalAccountKeys = localStorage.getItem("msal.account.keys");
  if (msalAccountKeys) {
    const msalAccountKeysArray = JSON.parse(msalAccountKeys)?.[0];
    const tokenKey = `${msalAccountKeysArray}idtoken-${process.env.REACT_APP_Shopify_Client_Id}----`;
    const accessToken = JSON.parse(localStorage.getItem(tokenKey) as string)?.secret;
    return accessToken;
  }
  return null;
};

// Add a request interceptor to include the token dynamically
instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
