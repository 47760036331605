import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THowItWorks, THowItWorksInitialStateType } from '../../../models/home/HowItWorksModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: THowItWorksInitialStateType = {
    HowItWorksData: [],
    HowItWorksLoading: true,
    HowItWorksError: undefined,
  };

  export const retrieveHowItWorks = createAsyncThunk(
    "home/HowItWorks/retrieve",
    async () => {
      const response = await HomePageServces.getAllHowItWorksService
      return [response.data];
    }
  );

const HowItWorksSlice = createSlice({
  name: 'HowItWorks',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHowItWorks.pending, (state) => {
        state.HowItWorksLoading = true;
      })
      .addCase(retrieveHowItWorks.fulfilled, (state, action) => {
        if(action.payload){
          state.HowItWorksLoading = false;
          state.HowItWorksData = action.payload as THowItWorks[];
        }
      })
      .addCase(retrieveHowItWorks.rejected, (state, action) => {
        state.HowItWorksLoading = false;
      });
  },
});

export default HowItWorksSlice.reducer;