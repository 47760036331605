import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  TCustomerAddress,
  TAddCustomerAddressInitialStateType,
  TUpdateCustomerAddressInitialStateType,
  TSetCustomerAddressInitialStateType,
  TGetCustomerAddressInitialStateType,
  TDeleteCustomerAddressInitialStateType,
  TCustomerAddressUpdate,
  TCustomerInfoByIDInitialStateType,
  TCustomerInfoByID,
} from "../../../models/customer-info/UpdateCustomerModel";
import CustomerService from "../../../services/customer-info/CustomerService";

// Add Customer Address slice
const AdIinitialState: TAddCustomerAddressInitialStateType = {
  AddCustomerAddressData: [],
  AddCustomerAddressLoading: true,
  AddCustomerAddressError: undefined,
};

export type TAddCustomerAddress = {
  customerId: string;
  AddCustomerAddressBody: TCustomerAddress;
};

export const AddCustomerAddress = createAsyncThunk(
  "customer-info/AddCustomerAddress/Add",
  async (CustomerAddressInfo: TAddCustomerAddress, { rejectWithValue }) => {
    try {
      const response = await CustomerService.AddCustomerAddressService(
        CustomerAddressInfo.customerId,
        CustomerAddressInfo.AddCustomerAddressBody
      );
      const { data, status } = response
      return [{ data, status }];

    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error
      }
      return rejectWithValue([error.response.data]);
    }

  }
);

const AddCustomerAddressSlice = createSlice({
  name: "customer-info/AddCustomerAddress",
  initialState: AdIinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddCustomerAddress.pending, (state) => {
        state.AddCustomerAddressLoading = true;
      })
      .addCase(AddCustomerAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.AddCustomerAddressLoading = false;
          state.AddCustomerAddressData = action.payload as TCustomerAddressUpdate[];
        }
      })
      .addCase(AddCustomerAddress.rejected, (state, action) => {
        state.AddCustomerAddressLoading = false;
      });
  },
});

// Update Customer Address slice

const updateInitialState: TUpdateCustomerAddressInitialStateType = {
  UpdateCustomerAddressData: [],
  UpdateCustomerAddressLoading: true,
  UpdateCustomerAddressError: undefined,
};

export type TUpdateCustomerAddress = {
  customerId: string;
  addressId: number;
  UpdateCustomerAddressBody: TCustomerAddress;
};

export const UpdateCustomerAddress = createAsyncThunk(
  "customer-info/UpdateCustomerAddress/Add",
  async (CustomerAddressInfo: TUpdateCustomerAddress, { rejectWithValue }) => {
    try {
      const response = await CustomerService.UpdateCustomerAddressService(
        CustomerAddressInfo.customerId,
        CustomerAddressInfo.addressId,
        CustomerAddressInfo.UpdateCustomerAddressBody
      );
      const { data, status } = response
      return [{ data, status }];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error
      }
      return rejectWithValue([error.response.data]);
    }

  }
);

const UpdateCustomerAddressSlice = createSlice({
  name: "customer-info/UpdateCustomerAddress",
  initialState: updateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCustomerAddress.pending, (state) => {
        state.UpdateCustomerAddressLoading = true;
      })
      .addCase(UpdateCustomerAddress.fulfilled, (state, action) => {

        if (action.payload) {
          state.UpdateCustomerAddressLoading = false;
          state.UpdateCustomerAddressData =
            action.payload as TCustomerAddressUpdate[];
        }
      })
      .addCase(UpdateCustomerAddress.rejected, (state, action) => {
        state.UpdateCustomerAddressLoading = false;
      });
  },
});

// Set Address Slice
const setInitialState: TSetCustomerAddressInitialStateType = {
  SetCustomerAddressData: [],
  SetCustomerAddressLoading: true,
  SetCustomerAddressError: undefined,
};

export type TSetCustomerAddress = {
  customerId: string;
  addressId: number;
};

export const SetCustomerAddress = createAsyncThunk(
  "customer-info/SetCustomerAddress/Add",
  async (data: TSetCustomerAddress) => {
    const response = await CustomerService.SetCustomerAddressService(
      data.customerId,
      data.addressId
    );
    return [response.data];
  }
);

const SetCustomerAddressSlice = createSlice({
  name: "customer-info/SetCustomerAddress",
  initialState: setInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetCustomerAddress.pending, (state) => {
        state.SetCustomerAddressLoading = true;
      })
      .addCase(SetCustomerAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.SetCustomerAddressLoading = false;
          state.SetCustomerAddressData = action.payload as TCustomerAddress[];
        }
      })
      .addCase(SetCustomerAddress.rejected, (state, action) => {
        state.SetCustomerAddressLoading = false;
      });
  },
});

// Get all Address Slice
const getAllInitialState: TGetCustomerAddressInitialStateType = {
  GetCustomerAddressData: [],
  GetCustomerAddressLoading: true,
  GetCustomerAddressError: undefined,
};

export const GetAllCustomerAddress = createAsyncThunk(
  "customer-info/GetCustomerAddress/Add",
  async (customerId: string) => {
    const response = await CustomerService.GetAllCustomerAddressService(
      customerId
    );
    return response.data;
  }
);

const GetAllCustomerAddressSlice = createSlice({
  name: "customer-info/GetCustomerAddress",
  initialState: getAllInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCustomerAddress.pending, (state) => {
        state.GetCustomerAddressLoading = true;
      })
      .addCase(GetAllCustomerAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.GetCustomerAddressLoading = false;
          state.GetCustomerAddressData = action.payload as TCustomerAddress[];
        }
      })
      .addCase(GetAllCustomerAddress.rejected, (state, action) => {
        state.GetCustomerAddressLoading = false;
      });
  },
});

// Delete Address
const deleteInitialState: TDeleteCustomerAddressInitialStateType = {
  DeleteCustomerAddressData: [],
  DeleteCustomerAddressLoading: true,
  DeleteCustomerAddressError: undefined,
};

export type TDeleteCustomerAddress = {
  customerId: string;
  addressId: number;
};

export const DeleteCustomerAddress = createAsyncThunk(
  "customer-info/DeleteCustomerAddress/Add",
  async (CustomerAddressInfo: TDeleteCustomerAddress, { rejectWithValue }) => {
    try {
      const response = await CustomerService.DeleteCustomerAddressService(
        CustomerAddressInfo.customerId,
        CustomerAddressInfo.addressId
      );
      const { data, status } = response
      return [{ data, status }]
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error
      }
      return rejectWithValue([error.response.data]);
    }
  }
);

const DeleteCustomerAddressSlice = createSlice({
  name: "customer-info/DeleteCustomerAddress",
  initialState: deleteInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteCustomerAddress.pending, (state) => {
        state.DeleteCustomerAddressLoading = true;
      })
      .addCase(DeleteCustomerAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.DeleteCustomerAddressLoading = false;
          state.DeleteCustomerAddressData =
            action.payload as TCustomerAddressUpdate[];
        }
      })
      .addCase(DeleteCustomerAddress.rejected, (state, action) => {
        state.DeleteCustomerAddressLoading = false;
      });
  },
});

export const { reducer: AddCustomerAddressReducer } = AddCustomerAddressSlice;

export const { reducer: UpdateCustomerAddressReducer } =
  UpdateCustomerAddressSlice;

export const { reducer: SetCustomerAddressReducer } = SetCustomerAddressSlice;

export const { reducer: GetAllCustomerAddressReducer } =
  GetAllCustomerAddressSlice;

export const { reducer: DeleteCustomerAddressReducer } =
  DeleteCustomerAddressSlice;



// Get Customer info By ID
const GetCustomerInfoByIdInitialState: TCustomerInfoByIDInitialStateType = {
  CustomerInfoByIDData: [],
  CustomerInfoByIDLoading: true,
  CustomerInfoByIDError: undefined,
};



export const GetCustomerInfoById = createAsyncThunk(
  "customer-info/GetCustomerInfoById/Add",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await CustomerService.GetCustomerInfoByIDService(
        customerId
      );
      return [response.data]
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error
      }
      return rejectWithValue([error.response.data]);
    }
  }
);

const GetCustomerInfoByIdSlice = createSlice({
  name: "customer-info/GetCustomerInfoById",
  initialState: GetCustomerInfoByIdInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCustomerInfoById.pending, (state) => {
        state.CustomerInfoByIDLoading = true;
      })
      .addCase(GetCustomerInfoById.fulfilled, (state, action) => {
        if (action.payload) {
          state.CustomerInfoByIDLoading = false;
          state.CustomerInfoByIDData =
            action.payload as TCustomerInfoByID[];
        }
      })
      .addCase(GetCustomerInfoById.rejected, (state, action) => {
        state.CustomerInfoByIDLoading = false;
      });
  },
});


export const { reducer: GetCustomerInfoByIdReducer } =
  GetCustomerInfoByIdSlice;