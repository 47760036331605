import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THelp, THelpInitialStateType } from '../../../models/home/HelpModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: THelpInitialStateType = {
    HelpData: [],
    HelpLoading: true,
    HelpError: undefined,
  };

export const retrieveHelp = createAsyncThunk(
    "home/Help/retrieve",
    async () => {
      const response = await HomePageServces.getAllHelpService
      return [response.data];
    }
  );

const HelpSlice = createSlice({
  name: 'Help',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHelp.pending, (state) => {
        state.HelpLoading = true;
      })
      .addCase(retrieveHelp.fulfilled, (state, action) => {
        if(action.payload){
          state.HelpLoading = false;
          state.HelpData = action.payload as THelp[];
        }
      })
      .addCase(retrieveHelp.rejected, (state, action) => {
        state.HelpLoading = false;
      });
  },
});


export default HelpSlice.reducer;