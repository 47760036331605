import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { THorseGender, THorseGenderInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: THorseGenderInitialStateType = {
    HorseGenderData: [],
    HorseGenderLoading: true,
    HorseGenderError: undefined,
};


export const GetAllHorseGender = createAsyncThunk(
    "customer-info/HorseGender/getHorseGender",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.horseGender();
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const HorseGenderSlice = createSlice({
    name: 'customer-info/HorseGender/getAllHorseGender',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseGender.pending, (state) => {
                state.HorseGenderLoading = true;
            })
            .addCase(GetAllHorseGender.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HorseGenderLoading = false;
                    state.HorseGenderData = action.payload as THorseGender[];
                }
            })
            .addCase(GetAllHorseGender.rejected, (state, action) => {
                state.HorseGenderLoading = false;
            });
    },
});


export default HorseGenderSlice.reducer;




