import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TScience, TScienceInitialStateType } from '../../../models/home/ScienceModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: TScienceInitialStateType = {
    ScienceData: [],
    ScienceLoading: true,
    ScienceError: undefined,
  };

export const retrieveScience = createAsyncThunk(
    "home/Science/retrieve",
    async () => {
      const response = await HomePageServces.getAllScienceService
      return [response.data];
    }
  );

const ScienceSlice = createSlice({
  name: 'Science',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveScience.pending, (state) => {
        state.ScienceLoading = true;
      })
      .addCase(retrieveScience.fulfilled, (state, action) => {
        if(action.payload){
          state.ScienceLoading = false;
          state.ScienceData = action.payload as TScience[];
        }
      })
      .addCase(retrieveScience.rejected, (state, action) => {
        state.ScienceLoading = false;
      });
  },
});


export default ScienceSlice.reducer;