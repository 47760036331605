import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ScienceInitialStateType, ScienceDiscoveryInitialStateType, TScience } from '../../models/ScienceModel';
import ScienceService from '../../services/ScienceService';


const ScienceInitialState: ScienceInitialStateType = {
    ScienceData: [],
    ScienceLoading: true,
    ScienceError : undefined,
};


export const getScienceThunk = createAsyncThunk(
    "Science ",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await ScienceService.getScience()
            // const { data, status } = response
            return [response.data]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetScienceSlice = createSlice({
    name: 'Science ',
    initialState: ScienceInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScienceThunk.pending, (state) => {
                state.ScienceLoading = true;
            })
            .addCase(getScienceThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ScienceLoading = false;
                    state.ScienceData = action.payload as TScience[];
                }
            })
            .addCase(getScienceThunk.rejected, (state, action) => {
                state.ScienceLoading = false;
            });
    },
});

export const { reducer: GetScienceReducer } =
GetScienceSlice;



const ScienceDiscoveryInitialState: ScienceDiscoveryInitialStateType = {
    ScienceDiscoveryData: [],
    ScienceDiscoveryLoading: true,
    ScienceDiscoveryError : undefined,
};


export const getScienceDiscoveryThunk = createAsyncThunk(
    "Science Discovery",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await ScienceService.getScienceDiscovery()
            // const { data, status } = response
            return [response.data]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetScienceDiscoverySlice = createSlice({
    name: 'Science Discovery',
    initialState: ScienceDiscoveryInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScienceDiscoveryThunk.pending, (state) => {
                state.ScienceDiscoveryLoading = true;
            })
            .addCase(getScienceDiscoveryThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ScienceDiscoveryLoading = false;
                    state.ScienceDiscoveryData = action.payload as TScience[];
                }
            })
            .addCase(getScienceDiscoveryThunk.rejected, (state, action) => {
                state.ScienceDiscoveryLoading = false;
            });
    },
});


export const { reducer: GetScienceDiscoveryReducer } =
GetScienceDiscoverySlice;




