import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TCartInitialStateType, TCart, TCreateCartInitialStateType, TCreateCart, TCartUpdate } from '../../../models/cart/cartModel';
import { CartServces } from '../../../services/cart/cartServices';


const initialState: TCartInitialStateType = {
    CartData: [],
    CartLoading: true,
    CartError : undefined,
};


export const getCartThunk = createAsyncThunk(
    "Contact us",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await CartServces.getCartItem()
            // const { data, status } = response
            return [response.data]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetCartSlice = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCartThunk.pending, (state) => {
                state.CartLoading = true;
            })
            .addCase(getCartThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.CartLoading = false;
                    state.CartData = action.payload as TCart[];
                }
            })
            .addCase(getCartThunk.rejected, (state, action) => {
                state.CartLoading = false;
            });
    },
});


export const { reducer: GetCartReducer } = GetCartSlice;


// Create Cart
const createCartinitialState: TCreateCartInitialStateType = {
    CreateCartData: [],
    CreateCartLoading: true,
    CreateCartError : undefined,
};


export const getCreateCartThunk = createAsyncThunk(
    "Contact us",
    async (item: TCreateCart[], { rejectWithValue }) => {
        try {
            const response = await CartServces.postCartItem(item)
            const { data, status } = response
            return [{ data, status }]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetCreateCartSlice = createSlice({
    name: 'team',
    initialState: createCartinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCreateCartThunk.pending, (state) => {
                state.CreateCartLoading = true;
            })
            .addCase(getCreateCartThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.CreateCartLoading = false;
                    state.CreateCartData = action.payload as TCartUpdate[];
                }
            })
            .addCase(getCartThunk.rejected, (state, action) => {
                state.CreateCartLoading = false;
            });
    },
});


export const { reducer: CreateCartReducer } = GetCreateCartSlice;