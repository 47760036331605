import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {TContnetInfo,TContentInitialStateType} from '../../../models/Content/ContentModel'
import { ContentServices } from "../../../services/Content/ContentService";

const initialState: TContentInitialStateType = {
    ContentData: [],
    ContentLoading: true,
    ContentError: undefined,
  };

  export const retrieveContentThunk = createAsyncThunk(
    "content/retrieve",
    async (path:string) => {
      const response = await ContentServices.getContent(path)
      console.log(response.data);
      return [response.data];
    }
  );

  export const ContentSlice=createSlice({
    name: 'content',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveContentThunk.pending, (state) => {
                state.ContentLoading = true;
            })
            .addCase(retrieveContentThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ContentLoading = false;
                    state.ContentData = action.payload as TContnetInfo[];
                }
            })
            .addCase(retrieveContentThunk.rejected, (state, action) => {
                state.ContentLoading = false;
            });
    },
});

export default ContentSlice.reducer