import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TAssignKitHorse, TAssignKitHorseResponse, TAssignKitInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: TAssignKitInitialStateType = {
    AssignKitData: [],
    AssignKitLoading: true,
    AssignKitError: undefined,
};


export const AssignKitThunk = createAsyncThunk(
    "customer-info/AssignKit/AssignKit",
    async (kitInfo: TAssignKitHorse, { rejectWithValue }) => {
        try {
            const response = await DashboardService.assignKit(kitInfo)
            const { data, status } = response
            return [{ data, status }];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const AssignKitSlice = createSlice({
    name: 'customer-info/AssignKit/AssignKit',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(AssignKitThunk.pending, (state) => {
                state.AssignKitLoading = true;
            })
            .addCase(AssignKitThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AssignKitLoading = false;
                    state.AssignKitData = action.payload as TAssignKitHorseResponse[];
                }
            })
            .addCase(AssignKitThunk.rejected, (state, action) => {
                state.AssignKitLoading = false;
            });
    },
});


export default AssignKitSlice.reducer;




