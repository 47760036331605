import http from "../http-common";


class ScienceService{
  getScience() {
    return http.get(`/Science/Science`);
  }
  getScienceDiscovery() {
    return http.get(`/Science/ScienceDiscovery`);
  }
}

export default new ScienceService();
