
import { ShopifyInstance } from "../../http-common";
import { TCreateCart } from "../../models/cart/cartModel";

export const CartServces = {
    getCartItem: () => {
        return ShopifyInstance.get('cart.js')
    },
    postCartItem: (cartItems: TCreateCart[] ) => {
        return ShopifyInstance.post('cart/add.js', cartItems)
    }
}


