// adobeUtils.ts
let adobeScriptLoaded = false;
let adobeDataLayerReady = false;
let adobeDataLayerCallbacks: (() => void)[] = [];

export const initializeAdobeDataLayer = () => {
  const adobeScriptUrl = process.env.REACT_APP_ADOBE_SCRIPT_URL;
  if (!adobeScriptLoaded && adobeScriptUrl) {
    const script = document.createElement('script');
    script.src = adobeScriptUrl;
    script.async = true;

    script.onload = () => {
      adobeScriptLoaded = true;
      adobeDataLayerReady = true;
      adobeDataLayerCallbacks.forEach(cb => cb());
      adobeDataLayerCallbacks = [];
    };

    script.onerror = () => {
      console.error('Failed to load the Adobe script');
    };

    document.head.appendChild(script);
  } else if (adobeScriptLoaded) {
    adobeDataLayerReady = true;
  }
};

export const pushToAdobeDataLayer = (data: any) => {
  console.log("adobe is ready ?",adobeDataLayerReady);
  if (adobeDataLayerReady && window.adobeDataLayer) {
    window.adobeDataLayer.push(data);
    console.log('Page view triggered for', window.adobeDataLayer);
  } else {
    //adobeDataLayerCallbacks.push(() => window.adobeDataLayer.push(data));
  }
  
};

export const triggerPageView = (pageName: string) => {
  pushToAdobeDataLayer({
    event: 'pageview',
    page: pageName,
  });
};
