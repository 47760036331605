import { createSlice } from "@reduxjs/toolkit";

type counterInitialTypes = {
    count: number;
};

export const catValue = localStorage?.getItem('cartItems')

const initialState: counterInitialTypes = {
    count: 1,
};

const CounterSlice = createSlice({
    name: "Counter",
    initialState: initialState,
    reducers: {
        Increment: (state) => {            
            state.count += 1;
        },
        Decrement: (state) => {
            state.count -= 1;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        },
    },
});

export const { Increment, Decrement, setCount } = CounterSlice.actions;
export default CounterSlice.reducer;
