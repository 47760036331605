
import http from "../http-common";

export const NavigationServces = {
  getAllNavigationHeader : (menuGroup: string) => {
        return http.get(`Navigation/navigationByMenuGroup/${menuGroup}`)
    }
}


