import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TBodyConditionScore, TBodyConditionScoreInitialStateType} from '../../../models/healthSurvey/HealthSurveyModel';
import HealthSurveyService from '../../../services/healthSurvey/HealthSurveyService';


const initialState: TBodyConditionScoreInitialStateType = {
    GetBodyConditionScoreData: [],
    GetBodyConditionScoreLoading: true,
    GetBodyConditionScoreError: undefined,
};


export const GetAllBodyConditionScore = createAsyncThunk(
    "customer-info/BodyConditionScore/getBodyConditionScore",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await HealthSurveyService.getBodyScore()
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetAllBodyConditionScoreSlice = createSlice({
    name: 'customer-info/BodyConditionScore/getAllBodyConditionScore',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllBodyConditionScore.pending, (state) => {
                state.GetBodyConditionScoreLoading = true;
            })
            .addCase(GetAllBodyConditionScore.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetBodyConditionScoreLoading = false;
                    state.GetBodyConditionScoreData = action.payload as TBodyConditionScore[];
                }
            })
            .addCase(GetAllBodyConditionScore.rejected, (state, action) => {
                state.GetBodyConditionScoreLoading = false;
            });
    },
});


export default GetAllBodyConditionScoreSlice.reducer;




