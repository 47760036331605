import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TSampleMailedInitialStateType, TSampleMailedResponse } from '../../../models/dashboard/MyHorsesModel';
// import { TSampleMailed, TSampleMailedInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: TSampleMailedInitialStateType = {
    SampleMailedData: [],
    SampleMailedLoading: true,
    SampleMailedError: undefined,
};

export type TSampleInfo = {
    kitID: string, isSample: boolean
}

export const SampleMailedThunk = createAsyncThunk(
    "customer-info/SampleMailed/getSampleMailed",
    async (sampleInfo: TSampleInfo, { rejectWithValue }) => {
        try {
            const response = await DashboardService.SampleMailed(sampleInfo.kitID, sampleInfo.isSample)
            return [response];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const SampleMailedSlice = createSlice({
    name: 'customer-info/SampleMailed/getAllSampleMailed',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(SampleMailedThunk.pending, (state) => {
                state.SampleMailedLoading = true;
            })
            .addCase(SampleMailedThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.SampleMailedLoading = false;
                    state.SampleMailedData = action.payload as TSampleMailedResponse[];
                }
            })
            .addCase(SampleMailedThunk.rejected, (state, action) => {
                state.SampleMailedLoading = false;
            });
    },
});


export default SampleMailedSlice.reducer;




