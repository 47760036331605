import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TAddHorse, TAddHorseInitialStateType, TCreateHorseResponse, TEditHorse, TgetEditHorseInitialStateType, TMyHorse, TUpdateHorseInitialStateType, TUpdatHorse, TUpdatHorseResponse } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: TAddHorseInitialStateType = {
    AddHorseData: [],
    AddHorseLoading: true,
    AddHorseError: undefined,
};


export const CreateHorse = createAsyncThunk(
    "customer-info/AddHorse/getAddHorse",
    async (horseInfo: TAddHorse, { rejectWithValue }) => {
        try {
            const response = await DashboardService.CreateHorse(horseInfo)
            const { data, status } = response
            return [{ data, status }];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const CreateHorseSlice = createSlice({
    name: 'customer-info/AddHorse/getAllAddHorse',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(CreateHorse.pending, (state) => {
                state.AddHorseLoading = true;
            })
            .addCase(CreateHorse.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AddHorseLoading = false;
                    state.AddHorseData = action.payload as TCreateHorseResponse[];
                }
            })
            .addCase(CreateHorse.rejected, (state, action) => {
                state.AddHorseLoading = false;
            });
    },
});


export default CreateHorseSlice.reducer;


// get edit horse
const getEditinitialState: TgetEditHorseInitialStateType = {
    getEditHorseData: [],
    getEditLoading: true,
    getEditError: undefined,
};


export const getEditHorse = createAsyncThunk(
    "customer-info/edithorse/getEditHorse",
    async (horseId: string, { rejectWithValue }) => {
        try {
            const response = await DashboardService.EditHorse(horseId)
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetEditHorseSlice = createSlice({
    name: 'customer-info/editHorse/getEditHorse',
    initialState: getEditinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEditHorse.pending, (state) => {
                state.getEditLoading = true;
            })
            .addCase(getEditHorse.fulfilled, (state, action) => {
                if (action.payload) {
                    state.getEditLoading = false;
                    state.getEditHorseData = action.payload as TEditHorse[];
                }
            })
            .addCase(getEditHorse.rejected, (state, action) => {
                state.getEditLoading = false;
            });
    },
});

export const { reducer: GetEditHorseReducer } =
    GetEditHorseSlice;




// update horse
const updateHorseinitialState: TUpdateHorseInitialStateType = {
    UpdateHorseData: [],
    UpdateHorseLoading: true,
    UpdateHorseError: undefined,
};


export const UpdateMyHorse = createAsyncThunk(
    "customer-info/update-horse",
    async (horseInfo: TUpdatHorse, { rejectWithValue }) => {
        try {
            const response = await DashboardService.updateHorse(horseInfo)
            const { data, status } = response
            return [{ data, status }];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const UpdateHorseSlice = createSlice({
    name: 'customer-info/update-horse',
    initialState: updateHorseinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UpdateMyHorse.pending, (state) => {
                state.UpdateHorseLoading = true;
            })
            .addCase(UpdateMyHorse.fulfilled, (state, action) => {
                if (action.payload) {
                    state.UpdateHorseLoading = false;
                    state.UpdateHorseData = action.payload as TUpdatHorseResponse[];
                }
            })
            .addCase(UpdateMyHorse.rejected, (state, action) => {
                state.UpdateHorseLoading = false;
            });
    },
});

export const { reducer: UpdateHorseReducer } =
    UpdateHorseSlice;


