import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TCustomerAddressUpdate, TUpdateCustomerBody, TUpdateCustomerInitialStateType } from '../../../models/customer-info/UpdateCustomerModel';
import CustomerService from '../../../services/customer-info/CustomerService';


const initialState: TUpdateCustomerInitialStateType = {
  UpdateCustomerData: [],
  UpdateCustomerLoading: true,
  UpdateCustomerError: null,
};

export type TUpdateCustomer = {
  customerId: string
  UpdateCustomerBody: TUpdateCustomerBody
}

export const UpdateCustomer = createAsyncThunk(
  "customer-info/UpdateCustomer/Update",
  async (CustomerAddressInfo: TUpdateCustomer, { rejectWithValue }) => {
    try {
      const response = await CustomerService.UpdateCustomerService(CustomerAddressInfo.customerId, CustomerAddressInfo.UpdateCustomerBody)
      const { data, status } = response
      return [{ data, status }];
    } catch (error: any) {

      // If an error occurs, you can return the error data
      if (!error.response) {
        throw error
      }      
      
      return rejectWithValue([error.response]);
    }
  }
);

const UpdateCustomerSlice = createSlice({
  name: 'customer-info/UpdateCustomer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCustomer.pending, (state) => {
        state.UpdateCustomerLoading = true;
      })
      .addCase(UpdateCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.UpdateCustomerLoading = false;
          state.UpdateCustomerData = action.payload as TCustomerAddressUpdate[];
        }
      })
      .addCase(UpdateCustomer.rejected, (state, action) => {
        state.UpdateCustomerLoading = false;
        if (action.payload) {          
          // Specify the type of action.payload.status using type casting
          state.UpdateCustomerError = action.payload
        }
      });
  },
});


export default UpdateCustomerSlice.reducer;