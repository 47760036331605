import http from "../../http-common";

export class ReportsService{
  getReportStatusAndResult(kitID: string) {
    return http.get(`/Report/ReportStatusAndResult?kitID=${kitID}`);
  }
  getReportCharts(kitID: string) {
    return http.get(`/Report/Charts?kitID=${kitID}`);
  }

  getCategoryAndGroup() {
    return http.get(`/Report/CategoryAndGroup`);
  }

  getCompareChart(groupID: number, kitID: string) {
    return http.get(`/Report/CompareChart?groupID=${groupID}&kitID=${kitID}`);
  }

  getRationChecker(kitID: string) {
    return http.get(`/Report/RationCheckerFrontEnd?kitID=${kitID}`);
  }

  getReportsPrint(kitID: string) {
    return http.get(`/Report/PrintReport?kitID=${kitID}`);
  }

}

export default new ReportsService();
