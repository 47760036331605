import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { THorseAge, THorseAgeInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: THorseAgeInitialStateType = {
    HorseAgeData: [],
    HorseAgeLoading: true,
    HorseAgeError: undefined,
};


export const GetAllHorseAge = createAsyncThunk(
    "customer-info/HorseAge/getHorseAge",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await DashboardService.horseAge();
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const HorseAgeSlice = createSlice({
    name: 'customer-info/HorseAge/getAllHorseAge',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseAge.pending, (state) => {
                state.HorseAgeLoading = true;
            })
            .addCase(GetAllHorseAge.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HorseAgeLoading = false;
                    state.HorseAgeData = action.payload as THorseAge[];
                }
            })
            .addCase(GetAllHorseAge.rejected, (state, action) => {
                state.HorseAgeLoading = false;
            });
    },
});


export default HorseAgeSlice.reducer;




