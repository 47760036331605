import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { THorseKitInfo, THorseKitInfoInitialStateType } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: THorseKitInfoInitialStateType = {
    HorseKitInfoData: [],
    HorseKitInfoLoading: true,
    HorseKitInfoError: undefined,
};
export type TKitListInfo = {
    horseID: string
    currentPage: number
    pageSize: number
}

export const HorseKitInfoThunk = createAsyncThunk(
    "Dashboard/HorseKitInfo/HorseKitInfo",
    async (kitListInfo: TKitListInfo, { rejectWithValue }) => {
        try {
            const response = await DashboardService.horseKitInfoByID(kitListInfo.horseID, kitListInfo.currentPage, kitListInfo.pageSize)
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const HorseKitInfoSlice = createSlice({
    name: 'Dashboard/HorseKitInfo/HorseKitInfo',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(HorseKitInfoThunk.pending, (state) => {
                state.HorseKitInfoLoading = true;
            })
            .addCase(HorseKitInfoThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HorseKitInfoLoading = false;
                    state.HorseKitInfoData = action.payload as THorseKitInfo[];
                }
            })
            .addCase(HorseKitInfoThunk.rejected, (state, action) => {
                state.HorseKitInfoLoading = false;
            });
    },
});


export default HorseKitInfoSlice.reducer;




