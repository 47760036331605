import http from "../../http-common";

export class ProductDetailsService{
  getProductDetails(productName: string) {
    return http.get(`/Product/ProductByName/${productName}`);
    
  }
}

export default new ProductDetailsService();
