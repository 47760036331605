import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THorseHealthHistory, THorseHealthHistoryInitialStateType} from '../../../models/healthSurvey/HealthSurveyModel';
import HealthSurveyService from '../../../services/healthSurvey/HealthSurveyService';


const initialState: THorseHealthHistoryInitialStateType = {
    GetHorseHealthHistoryData: [],
    GetHorseHealthHistoryLoading: true,
    GetHorseHealthHistoryError: undefined,
};


export const GetAllHorseHealthHistory = createAsyncThunk(
    "customer-info/HorseHealthHistory/getHorseHealthHistory",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await HealthSurveyService.getAllHorseHealthHistory()
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetAllHorseHealthHistorySlice = createSlice({
    name: 'customer-info/HorseHealthHistory/getAllHorseHealthHistory',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseHealthHistory.pending, (state) => {
                state.GetHorseHealthHistoryLoading = true;
            })
            .addCase(GetAllHorseHealthHistory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetHorseHealthHistoryLoading = false;
                    state.GetHorseHealthHistoryData = action.payload as THorseHealthHistory[];
                }
            })
            .addCase(GetAllHorseHealthHistory.rejected, (state, action) => {
                state.GetHorseHealthHistoryLoading = false;
            });
    },
});


export default GetAllHorseHealthHistorySlice.reducer;




