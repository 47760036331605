import http from "../http-common";


class TeamService{
  getTeam() {
    return http.get(`/Team/TeamInfo`);
  }
}

export default new TeamService();
