import http from "../../http-common";
import { TCustomerAddress, TUpdateCustomerBody } from "../../models/customer-info/UpdateCustomerModel";

class CustomerService{
  UpdateCustomerService(customerId: string, UpdateCustomerBody: TUpdateCustomerBody) {
    return http.put(`/CustomerInfo/UpdateCustomer/${customerId}`, UpdateCustomerBody);
  }
  AddCustomerAddressService(customerId: string, createCustomer: TCustomerAddress){
    return http.post(`/CustomerInfo/AddAddress?customerId=${customerId}`, createCustomer);
  }
  UpdateCustomerAddressService(customerId: string, addressId: number, createCustomer: TCustomerAddress){
    return http.put(`/CustomerInfo/UpdateAddress?customerId=${customerId}&addressId=${addressId}`, createCustomer);
  }
  GetAllCustomerAddressService(customerId: string){
    return http.get(`/CustomerInfo/CustomerAddresses?customerId=${customerId}`);
  }
  SetCustomerAddressService(customerId: string, addressId: number){
    return http.put(`/CustomerInfo/SetDefaultAddress?customerId=${customerId}&addressId=${addressId}`);
  }
  DeleteCustomerAddressService(customerId: string, addressId: number){
    return http.delete(`CustomerInfo/DeleteAddress?customerId=${customerId}&addressId=${addressId}`);
  }
  GetCustomerInfoByIDService(customerId: string,){
    return http.get(`/CustomerInfo/CustomerInfoByID?customerID=${customerId}`);
  }
}

export default new CustomerService();
