import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

type TShopifyUserInfo = {
    authorityType: string;
    clientInfo: string;
    homeAccountId: string;
    environment: string;
    realm: string;
    idTokenClaims: {
      ver: string;
      iss: string;
      sub: string;
      aud: string;
      exp: number;
      acr: string;
      nonce: string;
      iat: number;
      auth_time: number;
      user_id: string;
      given_name: string;
      family_name: string;
      customer_id: string;
      idp: string;
      nbf: number;
    };
    localAccountId: string;
    username: string;
  };
  

const UserInfo = () => {
    // Get local storage msal.account.keys
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();  
    
    
  const [shopifyUserInfo, setShopifyUserInfo] = useState<TShopifyUserInfo>();
  const [shopifyFirstName, setShopifyFirstName] = useState<string>('')
  const [shopifyLastName, setShopifyLastName] = useState<string>('')
  const [shopifyEemailId, setShopifyEmailId] = useState<string>('')
  const [shopifyCustomerId, setShopifyCustomerId] = useState<string>('')

  const msalAccountKeys: string | null =
    localStorage.getItem("msal.account.keys");
  const msalAccountKeysArray = msalAccountKeys?.split('"');
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    if (msalAccountKeysArray) {
        let userInfo: TShopifyUserInfo  = JSON.parse(`${localStorage.getItem(msalAccountKeysArray[1])}`)
      setShopifyUserInfo(userInfo);
      setShopifyFirstName(userInfo.idTokenClaims.given_name)
      setShopifyLastName(userInfo.idTokenClaims.family_name)
      setShopifyEmailId(userInfo.idTokenClaims.user_id)
      setShopifyCustomerId(userInfo.idTokenClaims.customer_id)
    }
  }, [msalAccountKeys]);

//   useEffect(() => {
//     if (account) {
//         instance.acquireTokenSilent({
//             scopes: ["User.Read"],
//             account: account
//         }).then((response) => {
//             if(response) {
//                 callMsGraph(response.accessToken).then((result: any) => setApiData(result));
//             }
//         });
//     }
// }, [account, instance]);

  return {shopifyFirstName, shopifyLastName, shopifyEemailId, shopifyCustomerId, shopifyUserInfo, isAuthenticated }

}

export default UserInfo