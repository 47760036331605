import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TProductDetails, TProductDetailsInitialStateType } from '../../../models/product/productDetailModel';
import   ProductDetailsService  from '../../../services/product/ProductDetailsSevice';


const initialState: TProductDetailsInitialStateType = {
    ProductDetailsData: {} as TProductDetails,
    ProductDetailsLoading: true,
    ProductDetailsError: undefined,
  };

export const retrieveProductDetails = createAsyncThunk(
    "home/ProductDetails/retrieve",
    async (productName: string) => {
      const response = await ProductDetailsService.getProductDetails(productName)
      return response.data as TProductDetails;
    }
  );

const ProductDetailsSlice = createSlice({
  name: 'ProductDetails',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProductDetails.pending, (state) => {
        state.ProductDetailsLoading = true;
      })
      .addCase(retrieveProductDetails.fulfilled, (state, action) => {
        if(action.payload){
          state.ProductDetailsLoading = false;
          state.ProductDetailsData = action.payload;
        }
      })
      .addCase(retrieveProductDetails.rejected, (state, action) => {
        state.ProductDetailsLoading = false;
      });
  },
});


export default ProductDetailsSlice.reducer;