import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TPageConfigurations, TPageConfigurationsInitialStateType } from '../../models/PageConfigurationsModel';
import   { PageConfigurationsService }   from '../../services/PageConfigurationsService';


const initialState: TPageConfigurationsInitialStateType = {
    PageConfigurationsData: [],
    PageConfigurationsLoading: true,
    PageConfigurationsError: undefined,
  };

export const retrievePageConfigurations = createAsyncThunk(
    "home/PageConfigurations/retrieve",
    async () => {
      const response = await PageConfigurationsService.getAllPageConfigurations
      return [response.data];
    }
  );

const PageConfigurationsSlice = createSlice({
  name: 'PageConfigurations',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePageConfigurations.pending, (state) => {
        state.PageConfigurationsLoading = true;
      })
      .addCase(retrievePageConfigurations.fulfilled, (state, action) => {
        if(action.payload){
          state.PageConfigurationsLoading = false;
          state.PageConfigurationsData = action.payload as TPageConfigurations[];
        }
      })
      .addCase(retrievePageConfigurations.rejected, (state, action) => {
        state.PageConfigurationsLoading = false;
      })
  },
});


export default PageConfigurationsSlice.reducer;