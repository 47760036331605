import http from "../../http-common";
import { TAddHorse, TAssignKitHorse, TUpdatHorse } from "../../models/dashboard/MyHorsesModel";
import { THorseHealthSurvey } from "../../models/healthSurvey/HealthSurveyModel";

class HealthSurveyService {
  getAllHorseHealthHistory() {
    return http.get(`/HealthSurvey/HorseHealthHistory`);
  }
  getBodyScore(){
    return http.get(`/HealthSurvey/BodyScore`);
  }
  getHorseLifestyle(){
    return http.get(`/HealthSurvey/HorseLifestyle`);
  }
  getHealthSurveyByKitId(kitID: string){
    return http.get(`HealthSurvey/HealthSurvey?kitID=${kitID}`);
  }
  addHorseHealthSurvey(surveyInfor: THorseHealthSurvey){
    return http.patch(`/HealthSurvey/AddSurvey`, surveyInfor);
  }
}

export default new HealthSurveyService();