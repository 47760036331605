import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TSupplement, TSupplementInitialStateType } from '../../../models/home/SupplementModal';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: TSupplementInitialStateType = {
    SupplementData: [],
    SupplementLoading: true,
    SupplementError: undefined,
  };

  export const retrieveSupplement = createAsyncThunk(
    "home/Supplement/retrieve",
    async () => {
      const response = await HomePageServces.getAllSupplementService
      return [response.data];
    }
  );

const SupplementSlice = createSlice({
  name: 'Supplement',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveSupplement.pending, (state) => {
        state.SupplementLoading = true;
      })
      .addCase(retrieveSupplement.fulfilled, (state, action) => {
        if(action.payload){
          state.SupplementLoading = false;
          state.SupplementData = action.payload as TSupplement[];
        }
      })
      .addCase(retrieveSupplement.rejected, (state, action) => {
        state.SupplementLoading = false;
      });
  },
});

export default SupplementSlice.reducer;