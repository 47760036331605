import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TGetMyHorsesInitialStateType, TMyHorse } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: TGetMyHorsesInitialStateType = {
    GetMyHorsesData: [],
    GetMyHorsesLoading: true,
    GetMyHorsesError: undefined,
};

export type THorseInfo = {
    customerId: number,
    page: number
}

export const GetAllMyHorses = createAsyncThunk(
    "customer-info/MyHorses/getMyHorses",
    async (horseInfo: THorseInfo, { rejectWithValue }) => {
        try {
            const response = await DashboardService.GetAllHorsesService(horseInfo.customerId, horseInfo.page)
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetAllMyHorsesSlice = createSlice({
    name: 'customer-info/MyHorses/ getAllMyHorses',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllMyHorses.pending, (state) => {
                state.GetMyHorsesLoading = true;
            })
            .addCase(GetAllMyHorses.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetMyHorsesLoading = false;
                    state.GetMyHorsesData = action.payload as TMyHorse[];
                }
            })
            .addCase(GetAllMyHorses.rejected, (state, action) => {
                state.GetMyHorsesLoading = false;
            });
    },
});


export default GetAllMyHorsesSlice.reducer;