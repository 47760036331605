import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THorseLifeStyle, THorseLifeStyleInitialStateType} from '../../../models/healthSurvey/HealthSurveyModel';
import HealthSurveyService from '../../../services/healthSurvey/HealthSurveyService';


const initialState: THorseLifeStyleInitialStateType = {
    GetHorseLifeStyleData: {} as THorseLifeStyle,
    GetHorseLifeStyleLoading: true,
    GetHorseLifeStyleError: undefined,
};


export const GetAllHorseLifeStyle = createAsyncThunk(
    "customer-info/HorseLifeStyle/getHorseLifeStyle",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await HealthSurveyService.getHorseLifestyle()
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.data);
        }
    }
);


const GetAllHorseLifeStyleSlice = createSlice({
    name: 'customer-info/HorseLifeStyle/getAllHorseLifeStyle',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllHorseLifeStyle.pending, (state) => {
                state.GetHorseLifeStyleLoading = true;
            })
            .addCase(GetAllHorseLifeStyle.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetHorseLifeStyleLoading = false;
                    state.GetHorseLifeStyleData = action.payload;
                }
            })
            .addCase(GetAllHorseLifeStyle.rejected, (state, action) => {
                state.GetHorseLifeStyleLoading = false;
            });
    },
});


export default GetAllHorseLifeStyleSlice.reducer;




