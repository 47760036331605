import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CheckoutBody, CheckoutInitialStateType  } from '../../models/CheckoutModel';
import CheckoutService  from '../../services/CheckoutService';

const initialState: CheckoutInitialStateType = {
    CheckoutData: [],
    CheckoutLoading: true,
    CheckoutError: undefined,
  };

  export const Checkout = createAsyncThunk(
    "Checkout/retrieve",
    async (body: CheckoutBody) => {
      const response = await CheckoutService.CheckoutService(body)
      return [response.data];
    }
  );

const CheckoutSlice = createSlice({
  name: 'Checkout',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Checkout.pending, (state) => {
        state.CheckoutLoading = true;
      })
      .addCase(Checkout.fulfilled, (state, action) => {
        if(action.payload){
          state.CheckoutLoading = false;
          state.CheckoutData = action.payload as CheckoutBody[];
        }
      })
      .addCase(Checkout.rejected, (state, action) => {
        state.CheckoutLoading = false;
      });
  },
});

export default CheckoutSlice.reducer;