import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TMeetMQ, TMeetMQInitialStateType } from '../../../models/home/MeetMQModal';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: TMeetMQInitialStateType = {
    MeetMQData: [],
    MeetMQLoading: true,
    MeetMQError: undefined,
  };

  export const retrieveMeetMQ = createAsyncThunk(
    "home/MeetMQ/retrieve",
    async () => {
      const response = await HomePageServces.getAllMeetMQService
      return [response.data];
    }
  );

const MeetMQSlice = createSlice({
  name: 'MeetMQ',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMeetMQ.pending, (state) => {
        state.MeetMQLoading = true;
      })
      .addCase(retrieveMeetMQ.fulfilled, (state, action) => {
        if(action.payload){
          state.MeetMQLoading = false;
          state.MeetMQData = action.payload as TMeetMQ[];
        }
      })
      .addCase(retrieveMeetMQ.rejected, (state, action) => {
        state.MeetMQLoading = false;
      });
  },
});

export default MeetMQSlice.reducer;