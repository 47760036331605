import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HeroBanner, HeroBannerInitialStateType } from '../../../models/home/HeroBannerModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: HeroBannerInitialStateType = {
    HeroBannerData: [],
    HeroBannerLoading: true,
    HeroBannerError: undefined,
  };

  export const retrieveHeroBanner = createAsyncThunk(
    "home/HeroBanner/retrieve",
    async () => {
      const response = await HomePageServces.getAllHeroBannerService
      return [response.data];
    }
  );

const HeroBannerSlice = createSlice({
  name: 'HeroBanner',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHeroBanner.pending, (state) => {
        state.HeroBannerLoading = true;
      })
      .addCase(retrieveHeroBanner.fulfilled, (state, action) => {
        if(action.payload){
          state.HeroBannerLoading = false;
          state.HeroBannerData = action.payload as HeroBanner[];
        }
      })
      .addCase(retrieveHeroBanner.rejected, (state, action) => {
        state.HeroBannerLoading = false;
      });
  },
});

export default HeroBannerSlice.reducer;