import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TTermsAndConditions, TTermsAndConditionsInitialStateType} from '../../models/TermsAndConditionsModel';
import TermsAndConditionsService from '../../services/TermsAndConditionsService';


const initialState: TTermsAndConditionsInitialStateType = {
    TermsAndConditionsData: [],
    TermsAndConditionsLoading: true,
    TermsAndConditionsError: undefined,
};


export const GetAllTermsAndConditions = createAsyncThunk(
    "customer-info/TermsAndConditions/getTermsAndConditions",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await TermsAndConditionsService.getTermsAndConditions()
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetAllTermsAndConditionsSlice = createSlice({
    name: 'customer-info/TermsAndConditions/getAllTermsAndConditions',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllTermsAndConditions.pending, (state) => {
                state.TermsAndConditionsLoading = true;
            })
            .addCase(GetAllTermsAndConditions.fulfilled, (state, action) => {
                if (action.payload) {
                    state.TermsAndConditionsLoading = false;
                    state.TermsAndConditionsData = action.payload as TTermsAndConditions[];
                }
            })
            .addCase(GetAllTermsAndConditions.rejected, (state, action) => {
                state.TermsAndConditionsLoading = false;
            });
    },
});


export default GetAllTermsAndConditionsSlice.reducer;




