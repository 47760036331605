import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TProducts, TProductsInitialStateType } from '../../../models/product/productsModel';
// import  { HomePageServces } from '../../../services/home/HomeServices';
import ProductsMqService  from '../../../services/product/ProductsService';

const initialState: TProductsInitialStateType = {
    ProductsData: [],
    ProductsLoading: true,
    ProductsError: undefined,
  };

export const retrieveMqProducts = createAsyncThunk(
    "collection/retrieve",
    async (pageNumber: number, { rejectWithValue }) => {
      const response = await ProductsMqService.getMqProducts(pageNumber);
      return [response.data];
    }
  );

const ProductsMqSlice = createSlice({
  name: 'ProductsMq',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMqProducts.pending, (state) => {
        state.ProductsLoading = true;
      })
      .addCase(retrieveMqProducts.fulfilled, (state, action) => {
        if(action.payload){
          state.ProductsLoading = false;
          state.ProductsData = action.payload as TProducts[];
        }
      })
      .addCase(retrieveMqProducts.rejected, (state, action) => {
        state.ProductsLoading = false;
      });
  },
});


export default ProductsMqSlice.reducer;