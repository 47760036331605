import http from "../http-common";
import { CheckoutBody } from "../models/CheckoutModel";


class CheckoutService{
  CheckoutService(body :CheckoutBody) {
    return http.post(`/checkouts.json`, {
        line_items: [body]
    });
  }
}

export default new CheckoutService();
