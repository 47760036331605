import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TTeamInfo, TeamInitialStateType} from '../../models/TeamModel'
import TeamService from '../../services/teamService';


const initialState: TeamInitialStateType = {
    TeamData: [],
    TeamLoading: true,
    TeamError : undefined,
};


export const getTeamThunk = createAsyncThunk(
    "team",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await TeamService.getTeam()
            // const { data, status } = response
            return [response.data]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetTeamSlice = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTeamThunk.pending, (state) => {
                state.TeamLoading = true;
            })
            .addCase(getTeamThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.TeamLoading = false;
                    state.TeamData = action.payload as TTeamInfo[];
                }
            })
            .addCase(getTeamThunk.rejected, (state, action) => {
                state.TeamLoading = false;
            });
    },
});


export default GetTeamSlice.reducer;




