import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TProducts, TProductsInitialStateType } from '../../../models/home/ProductsModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: TProductsInitialStateType = {
    ProductsData: [],
    ProductsLoading: true,
    ProductsError: undefined,
  };

export const retrieveProducts = createAsyncThunk(
    "home/Products/retrieve",
    async () => {
      const response = await HomePageServces.getAllProductsService
      return [response.data];
    }
  );

const ProductsSlice = createSlice({
  name: 'Products',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProducts.pending, (state) => {
        state.ProductsLoading = true;
      })
      .addCase(retrieveProducts.fulfilled, (state, action) => {
        if(action.payload){
          state.ProductsLoading = false;
          state.ProductsData = action.payload as TProducts[];
        }
      })
      .addCase(retrieveProducts.rejected, (state, action) => {
        state.ProductsLoading = false;
      });
  },
});


export default ProductsSlice.reducer;