import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TContactUs, TContactUsInitialStateType, TSubmitContactUsForm, TSubmitContactUsFormInitialStateType, TSubmitContactUsFormResponse} from '../../models/ContactUsModel'
import { ContactUsService } from '../../services/ContactUsService';


const initialState: TContactUsInitialStateType = {
    ContactUsData: [],
    ContactUsLoading: true,
    ContactUsError : undefined,
};


export const getContactUsThunk = createAsyncThunk(
    "Contact us",
    async (__data, { rejectWithValue }) => {
        try {
            const response = await ContactUsService.GetContactUs()
            // const { data, status } = response
            return [response.data]
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetContactUsSlice = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContactUsThunk.pending, (state) => {
                state.ContactUsLoading = true;
            })
            .addCase(getContactUsThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ContactUsLoading = false;
                    state.ContactUsData = action.payload as TContactUs[];
                }
            })
            .addCase(getContactUsThunk.rejected, (state, action) => {
                state.ContactUsLoading = false;
            });
    },
});


export const { reducer: GetContactUsReducer } = GetContactUsSlice;




const SubmitContactUsInitialState: TSubmitContactUsFormInitialStateType = {
    SubmitContactUsFormData: [],
    SubmitContactUsFormLoading: true,
    SubmitContactUsFormError: undefined,
};


export const SubmitContactUsThunk = createAsyncThunk(
    "customer-info/SubmitContactUs/SubmitContactUs",
    async (contactInfo: TSubmitContactUsForm, { rejectWithValue }) => {
        try {
            const response = await ContactUsService.PostContactUs(contactInfo)
            const { data, status } = response
            return [{ data, status }];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const SubmitContactUsSlice = createSlice({
    name: 'customer-info/SubmitContactUs/SubmitContactUs',
    initialState: SubmitContactUsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(SubmitContactUsThunk.pending, (state) => {
                state.SubmitContactUsFormLoading = true;
            })
            .addCase(SubmitContactUsThunk.fulfilled, (state, action) => {
                if (action.payload) {
                    state.SubmitContactUsFormLoading = false;
                    state.SubmitContactUsFormData = action.payload as TSubmitContactUsFormResponse[];
                }
            })
            .addCase(SubmitContactUsThunk.rejected, (state, action) => {
                state.SubmitContactUsFormLoading = false;
            });
    },
});


export const { reducer: SubmitContactUsReducer } = SubmitContactUsSlice;



