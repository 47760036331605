import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TAdobe, TAdobeInitialStateType } from '../../../models/adobe/adobeModel';
import adobeService from '../../../services/adobe/adobeService';
import { AxiosResponse } from 'axios';

const initialState: TAdobeInitialStateType = {
    AdobeData: {} as TAdobe,
    AdobeLoading: true,
    AdobeError: undefined
}

export const retrieveAdobe = createAsyncThunk("AdobeInfo/retrieve", async (url: string) => {
    const response: AxiosResponse<any> = await adobeService.getAdobeInfo(url);
    return response.data as TAdobe;
});

const AdobeSlice = createSlice({
    name: "AdobeInfo",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAdobe.pending, (state) => {
                state.AdobeLoading = true;
            })
            .addCase(retrieveAdobe.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AdobeLoading = false;
                    state.AdobeData = action.payload as TAdobe;
                }
            })
            .addCase(retrieveAdobe.rejected, (state) => {
                state.AdobeLoading = false;
            })
    }
})

export default AdobeSlice.reducer;
