import { string } from 'prop-types';
import { Navigate } from 'react-router-dom';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TAdmin, TAdminInitialStateType, TApproveReportInitialStateType, TCheckUserAdminStatusInitialStateType, TSaveNotesInitialStateType } from '../../../../models/reports/admin/ReportsAdminModel';
import ReportsAdminService from '../../../../services/reports/admin/ReportsAdminService';

const initialState: TCheckUserAdminStatusInitialStateType = {
    CheckUserAdminStatusData: [],
    CheckUserAdminStatusLoading: true,
    CheckUserAdminStatusError: undefined,
};

export const retrieveCheckUserAdminStatus = createAsyncThunk(
    "home/CheckUserAdminStatus/retrieve",
    async (customerID: string, { rejectWithValue }) => {
        try {
            const response = await ReportsAdminService.getCheckUserAdminStatus(customerID)
            
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (error.response.status) {
                window.location.href = '/page-not-found'
            }
            if (!error.response) {
                throw error
            }

            return rejectWithValue([error.response.data]);
        }
    }
);

const CheckUserAdminStatusSlice = createSlice({
    name: 'CheckUserAdminStatus',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveCheckUserAdminStatus.pending, (state) => {
                state.CheckUserAdminStatusLoading = true;
            })
            .addCase(retrieveCheckUserAdminStatus.fulfilled, (state, action) => {
                if (action.payload) {
                    state.CheckUserAdminStatusLoading = false;
                    state.CheckUserAdminStatusData = action.payload  as boolean[];
                }
            })
            .addCase(retrieveCheckUserAdminStatus.rejected, (state, action) => {
                state.CheckUserAdminStatusLoading = false;
            });
    },
});


export const { reducer: CheckUserAdminStatusReducer } = CheckUserAdminStatusSlice;



const AdmininitialState: TAdminInitialStateType = {
    AdminData: [],
    AdminLoading: true,
    AdminError: undefined,
};

export type TAdminTable = {
    customerID: string,
    pageNumber: number,
    pageSize: number,
    searchText:string
}

export const retrieveAdmin = createAsyncThunk(
    "home/Admin/retrieve",
    async (AdminTable: TAdminTable) => {
        const response = await ReportsAdminService.getAdmin(AdminTable.customerID, AdminTable.pageNumber, AdminTable.pageSize,AdminTable.searchText)
        return [response.data];
    }
);

const AdminSlice = createSlice({
    name: 'Admin',
    initialState: AdmininitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAdmin.pending, (state) => {
                state.AdminLoading = true;
            })
            .addCase(retrieveAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AdminLoading = false;
                    state.AdminData = action.payload as TAdmin[];
                }
            })
            .addCase(retrieveAdmin.rejected, (state, action) => {
                state.AdminLoading = false;
            });
    },
});


export const { reducer: AdminReducer } = AdminSlice;



// Approve Report slice
const ApproveReportInitialState: TApproveReportInitialStateType = {
    ApproveReportData: [],
    ApproveReportLoading: true,
    ApproveReportError: undefined,
};

export const retrieveApproveReport = createAsyncThunk(
    "home/ApproveReport/retrieve",
    async (kitID: string, { rejectWithValue }) => {
        try {
            const response = await ReportsAdminService.getApproveReport(kitID)
            
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (error.response.status) {
                window.location.href = '/page-not-found'
            }
            if (!error.response) {
                throw error
            }

            return rejectWithValue([error.response.data]);
        }
    }
);

const ApproveReportSlice = createSlice({
    name: 'ApproveReport',
    initialState: ApproveReportInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveApproveReport.pending, (state) => {
                state.ApproveReportLoading = true;
            })
            .addCase(retrieveApproveReport.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ApproveReportLoading = false;
                    state.ApproveReportData = action.payload  as boolean[];
                }
            })
            .addCase(retrieveApproveReport.rejected, (state, action) => {
                state.ApproveReportLoading = false;
            });
    },
});


export const { reducer: ApproveReportReducer } = ApproveReportSlice;


// Note Report slice
const SaveNotesInitialState: TSaveNotesInitialStateType = {
    SaveNotesData: [],
    SaveNotesLoading: true,
    SaveNotesError: undefined,
};

export type TSaveNote = {
    kitID: string,
    Notes: string
}

export const retrieveSaveNotes = createAsyncThunk(
    "home/SaveNotes/retrieve",
    async (saveNotes: TSaveNote, { rejectWithValue }) => {
        try {
            const response = await ReportsAdminService.getSaveNotes(saveNotes.kitID, saveNotes.Notes)
            
            return [response.data];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (error.response.status) {
                window.location.href = '/page-not-found'
            }
            if (!error.response) {
                throw error
            }

            return rejectWithValue([error.response.data]);
        }
    }
);

const SaveNotesSlice = createSlice({
    name: 'SaveNotes',
    initialState: SaveNotesInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSaveNotes.pending, (state) => {
                state.SaveNotesLoading = true;
            })
            .addCase(retrieveSaveNotes.fulfilled, (state, action) => {
                if (action.payload) {
                    state.SaveNotesLoading = false;
                    state.SaveNotesData = action.payload  as boolean[];
                }
            })
            .addCase(retrieveSaveNotes.rejected, (state, action) => {
                state.SaveNotesLoading = false;
            });
    },
});


export const { reducer: SaveNotesReducer } = SaveNotesSlice;
