import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  TProhibitedInitialStateType } from '../../models/ProhibitedModel';
import   { ProhibitedService }   from '../../services/ProhibitedPageService';

const initialState: TProhibitedInitialStateType = {
    ProhibitedData: [],
    ProhibitedLoading: true,
    ProhibitedError: undefined,
  };

export const retrieveProhibited = createAsyncThunk(
    "home/Prohibited/retrieve",
    async () => {
      const response = await ProhibitedService.getProhibited
      return [response.data];
    }
  );

const ProhibitedSlice = createSlice({
  name: 'Prohibited',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProhibited.pending, (state) => {
        state.ProhibitedLoading = true;
      })
      .addCase(retrieveProhibited.fulfilled, (state, action) => {
        if(action.payload){
          state.ProhibitedLoading = false;
          state.ProhibitedData = action.payload as string[];
        }
      })
      .addCase(retrieveProhibited.rejected, (state, action) => {
        state.ProhibitedLoading = false;
      })
  },
});


export default ProhibitedSlice.reducer;