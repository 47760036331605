import { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageLoader from "../components/re-usable/PageLoader";
import AppRouter, { TRoute } from "../Routes";
import { useAppDispatch, useTypedSelector, RootState } from "../redux/store";
import { retrieveAdobe } from '../redux/slice/adobe/adobeSlice';
import { triggerPageView } from "../utils/adobeUtils";

const DefaultLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { hash } = location;

  const [scrollY, setScrollY] = useState<number>(window.scrollY);
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  const extractTextFromUrl = (url: string) => {
    const guidRegex = /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i;
    const mqeRegex = /\/MQE-\d{5}-\d{2}/i;

    let modifiedUrl = url.replace(guidRegex, '');
    modifiedUrl = modifiedUrl.replace(mqeRegex, '');
    modifiedUrl = modifiedUrl.replace(/\/{2,}/g, '/');

    return modifiedUrl;
  };

  const modifiedUrl = extractTextFromUrl(location.pathname);

  useEffect(() => {
    if (modifiedUrl) {
      dispatch(retrieveAdobe(modifiedUrl));
    }
  }, [dispatch, modifiedUrl]);

  const { AdobeData } = useTypedSelector((state: RootState) => state.Adobe);

  useEffect(() => {
    // Trigger the page view event only when the pathname changes
    if (AdobeData.pageType && location.pathname !== previousPath) {
      triggerPageView(AdobeData.pageType);
      setPreviousPath(location.pathname);
    }
  }, [AdobeData, previousPath]);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (hash) {
      const checkElementAndScroll = () => {
        const targetElement = document.getElementById(hash.substring(1));

        if (targetElement) {
          const offset = 100;
          const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        } else {
          setTimeout(checkElementAndScroll, 100);
        }
      };

      checkElementAndScroll();
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash, navigate]);

  return (
    <>
      <Header />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {AppRouter.map((route: TRoute, index: number) => (
            route.component ? (
              <Route key={index} path={route.path} element={route.component} />
            ) : null
          ))}
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default DefaultLayout;
