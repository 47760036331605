import { createSlice } from '@reduxjs/toolkit';
import UserInfo from '../../hooks/userInfo';

// const {
//     isAuthenticated
//  } = UserInfo();

const initialState = {
    
    state: {
        isFetching: false,
    },
    user: {
        isAuthenticated: true, // Call the function to get the initial value
    },
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsFetching: (state) => {
            state.state.isFetching = true;
        },
    }
});

export const {
    setIsFetching,
} = userSlice.actions;

export default userSlice.reducer;
