import { LogLevel } from "@azure/msal-browser";

export interface B2CPolicies {
  names: {
    signUpSignIn: string;
    forgotPassword: string;
    editProfile: string;
  };
  authorities: {
    signUpSignIn: {
      authority: string;
    };
    forgotPassword: {
      authority: string;
    };
    editProfile: {
      authority: string;
    };
  };
  authorityDomain: string;
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies: B2CPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_LOL_Shopify_SignUpSignIn as string,
    forgotPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_LOL_Shopify_SignUpSignIn_Authority as string,
  },
  forgotPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
  },
  editProfile: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
  },
  },
  authorityDomain: process.env.REACT_APP_LOL_Shopify_AuthorityDomain as string,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_Shopify_Client_Id as string, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_LOL_Shopify_Redirection_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: process.env.REACT_APP_LOL_Shopify_Redirection_URL, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false// If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
  domainHint: "Shopify",
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid", "profile", "offline"],
  domainHint: "shopify.com",
};