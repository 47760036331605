
import http from "../http-common";
import { TSubmitContactUsForm } from "../models/ContactUsModel";

export const ContactUsService = {
    GetContactUs(){
        return http.get(`/ContactUs/ContactUs`)
    },

    PostContactUs(data: TSubmitContactUsForm){
        return http.patch(`/ContactUs/SubmitContactUsForm`, data)
    }

}


