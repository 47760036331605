// import { useNavigate } from "react-router-dom";
// import { useIsAuthenticated, useMsal } from "@azure/msal-react";
// import React, { useEffect, useState } from "react";

// interface ProtectedRouteProps {
//   component: React.JSX.Element;
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
//   const { accounts } = useMsal();
//   console.log(accounts,'accounts')
//   debugger
//   const isAuthenticated: boolean = accounts.length > 0 ? accounts[0].idTokenClaims
//   ?.customer_id ? true : false : false;
//   const navigate = useNavigate();

//   if (isAuthenticated) {
//     return component;
//   } else {
//     navigate("/prohibited");
//     return null;
//   }
// };

// export default ProtectedRoute;


import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import PageLoader from "../components/re-usable/PageLoader";
import { loginRequest } from "../authConfig";

interface ProtectedRouteProps {
  component: React.JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();
  const [isUser, setIsUser] = useState<boolean | null>(null);

  useEffect(() => {
    if (accounts.length > 0) {
      setIsUser(true);
    } else {
      instance.handleRedirectPromise().then((response) => {
        if (response !== null && response.account !== null) {
          setIsUser(true);
        } else {
          setIsUser(false);
        }
      }).catch((error) => {
        console.error(error);
        setIsUser(false);
      });
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (isUser === false) {
      navigate("/prohibited");
      // instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }
  }, [isUser, navigate]);

  if (isUser === null) {
    return <PageLoader />; // Or your preferred loading indicator
  }

  return isUser ? component : null;
};

export default ProtectedRoute;
