import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Intro, IntroInitialStateType } from '../../../models/home/IntroModel';
import  { HomePageServces } from '../../../services/home/HomeServices';


const initialState: IntroInitialStateType = {
    IntroData: [],
    IntroLoading: true,
    IntroError: undefined,
  };

  export const retrieveIntro = createAsyncThunk(
    "home/Intro/retrieve",
    async () => {
      const response = await HomePageServces.getAllIntroService
      return [response.data];
    }
  );

const IntroSlice = createSlice({
  name: 'Intro',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveIntro.pending, (state) => {
        state.IntroLoading = true;
      })
      .addCase(retrieveIntro.fulfilled, (state, action) => {
        if(action.payload){
          state.IntroLoading = false;
          state.IntroData = action.payload as Intro[];
        }
      })
      .addCase(retrieveIntro.rejected, (state, action) => {
        state.IntroLoading = false;
      });
  },
});

export default IntroSlice.reducer;