
import http from "../../http-common";

export const HomePageServces = {
  getAllHeroBannerService: http.get(`Home/HeroBanner`),
  getAllIntroService: http.get(`Home/Intro`),
  getAllMeetMQService: http.get(`Home/MeetMq`),
  getAllSupplementService: http.get(`Home/Supplement`),
  getAllHowItWorksService: http.get(`Home/HowItWorks`),
  getAllScienceService: http.get(`Home/Science`),
  getAllPurinaTeamService: http.get(`Home/PurinaTeam`),
  getAllProductsService: http.get(`Home/Products`),
  getAllHelpService: http.get(`Home/Help`),
}


