import { string } from 'prop-types';
import { Navigate } from 'react-router-dom';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRationChecker, TRationCheckerInitialStateType, TReportCategoryAndGroup, TReportCategoryAndGroupInitialStateType, TReportCharts, TReportChartsInitialStateType, TReportCompareChart, TReportCompareChartInitialStateType, TReportsPrint, TReportsPrintInitialStateType, TReportStatusAndResultInitialStateType, TReportStatusAndResultResponse } from '../../../models/reports/ReportsModel';
import ReportStatusAndResultService from '../../../services/reports/ReportsService';

const initialState: TReportStatusAndResultInitialStateType = {
  ReportStatusAndResultData: [],
  ReportStatusAndResultLoading: true,
  ReportStatusAndResultError: undefined,
};

export const retrieveReportStatusAndResult = createAsyncThunk(
  "home/ReportStatusAndResult/retrieve",
  async (kitID: string, { rejectWithValue }) => {
    try {
      const response = await ReportStatusAndResultService.getReportStatusAndResult(kitID)
      const { data, status } = response
      return [{ data, status }];
    } catch (error: any) {
      // If an error occurs, you can return the error data
      if (error.response.status) {
        window.location.href = '/page-not-found'
      }
      if (!error.response) {
        throw error
      }

      return rejectWithValue([error.response.data]);
    }
  }
);

const ReportStatusAndResultSlice = createSlice({
  name: 'ReportStatusAndResult',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveReportStatusAndResult.pending, (state) => {
        state.ReportStatusAndResultLoading = true;
      })
      .addCase(retrieveReportStatusAndResult.fulfilled, (state, action) => {
        if (action.payload) {
          state.ReportStatusAndResultLoading = false;
          state.ReportStatusAndResultData = action.payload as TReportStatusAndResultResponse[];
        }
      })
      .addCase(retrieveReportStatusAndResult.rejected, (state, action) => {
        state.ReportStatusAndResultLoading = false;
      });
  },
});


export default ReportStatusAndResultSlice.reducer;



const ReportChartsinitialState: TReportChartsInitialStateType = {
  ReportChartsData: [],
  ReportChartsLoading: true,
  ReportChartsError: undefined,
};

export const retrieveReportCharts = createAsyncThunk(
  "home/ReportCharts/retrieve",
  async (kitID: string) => {
    const response = await ReportStatusAndResultService.getReportCharts(kitID)
    return [response.data];
  }
);

const ReportChartsSlice = createSlice({
  name: 'ReportCharts',
  initialState: ReportChartsinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveReportCharts.pending, (state) => {
        state.ReportChartsLoading = true;
      })
      .addCase(retrieveReportCharts.fulfilled, (state, action) => {
        if (action.payload) {
          state.ReportChartsLoading = false;
          state.ReportChartsData = action.payload as TReportCharts[];
        }
      })
      .addCase(retrieveReportCharts.rejected, (state, action) => {
        state.ReportChartsLoading = false;
      });
  },
});


export const { reducer: ReportChartsReducer } = ReportChartsSlice;



// Reports CategoryAndGroup
const ReportCategoryAndGroupinitialState: TReportCategoryAndGroupInitialStateType = {
  ReportCategoryAndGroupData: [],
  ReportCategoryAndGroupLoading: true,
  ReportCategoryAndGroupError: undefined,
};

export const retrieveReportCategoryAndGroup = createAsyncThunk(
  "home/ReportCategoryAndGroup/retrieve",
  async () => {
    const response = await ReportStatusAndResultService.getCategoryAndGroup()
    return [response.data];
  }
);

const ReportCategoryAndGroupSlice = createSlice({
  name: 'ReportCategoryAndGroup',
  initialState: ReportCategoryAndGroupinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveReportCategoryAndGroup.pending, (state) => {
        state.ReportCategoryAndGroupLoading = true;
      })
      .addCase(retrieveReportCategoryAndGroup.fulfilled, (state, action) => {
        if (action.payload) {
          state.ReportCategoryAndGroupLoading = false;
          state.ReportCategoryAndGroupData = action.payload as TReportCategoryAndGroup[];
        }
      })
      .addCase(retrieveReportCategoryAndGroup.rejected, (state, action) => {
        state.ReportCategoryAndGroupLoading = false;
      });
  },
});


export const { reducer: ReportCategoryAndGroupReducer } = ReportCategoryAndGroupSlice;




// Reports CompareChart
const ReportCompareChartinitialState: TReportCompareChartInitialStateType = {
  ReportCompareChartData: [],
  ReportCompareChartLoading: true,
  ReportCompareChartError: undefined,
};

export type TCompareParams = {
  groupID: number, 
  kitID: string
}


export const retrieveReportCompareChart = createAsyncThunk(
  "home/ReportCompareChart/retrieve",
  async (compareParams: TCompareParams) => {
    const response = await ReportStatusAndResultService.getCompareChart(compareParams.groupID, compareParams.kitID)
    return [response.data];
  }
);

const ReportCompareChartSlice = createSlice({
  name: 'ReportCompareChart',
  initialState: ReportCompareChartinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveReportCompareChart.pending, (state) => {
        state.ReportCompareChartLoading = true;
      })
      .addCase(retrieveReportCompareChart.fulfilled, (state, action) => {
        if (action.payload) {
          state.ReportCompareChartLoading = false;
          state.ReportCompareChartData = action.payload as TReportCompareChart[];
        }
      })
      .addCase(retrieveReportCompareChart.rejected, (state, action) => {
        state.ReportCompareChartLoading = false;
      });
  },
});


export const { reducer: ReportCompareChartReducer } = ReportCompareChartSlice;


const RationCheckerinitialState: TRationCheckerInitialStateType = {
  RationCheckerData: [],
  RationCheckerLoading: true,
  RationCheckerError: undefined,
};


export const retrieveRationChecker = createAsyncThunk(
  "home/RationChecker/retrieve",
  async (kitID: string) => {
    const response = await ReportStatusAndResultService.getRationChecker(kitID)
    return [response.data];
  }
);

const RationCheckerSlice = createSlice({
  name: 'RationChecker',
  initialState: RationCheckerinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRationChecker.pending, (state) => {
        state.RationCheckerLoading = true;
      })
      .addCase(retrieveRationChecker.fulfilled, (state, action) => {
        if (action.payload) {
          state.RationCheckerLoading = false;
          state.RationCheckerData = action.payload as TRationChecker[];
        }
      })
      .addCase(retrieveRationChecker.rejected, (state, action) => {
        state.RationCheckerLoading = false;
      });
  },
});


export const { reducer: RationCheckerReducer } = RationCheckerSlice;



const ReportsPrintinitialState: TReportsPrintInitialStateType = {
  ReportsPrintData: [],
  ReportsPrintLoading: true,
  ReportsPrintError: undefined,
};


export const retrieveReportsPrint = createAsyncThunk(
  "home/ReportsPrint/retrieve",
  async (kitID: string) => {
    const response = await ReportStatusAndResultService.getReportsPrint(kitID)
    return [response.data];
  }
);

const ReportsPrintSlice = createSlice({
  name: 'ReportsPrint',
  initialState: ReportsPrintinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveReportsPrint.pending, (state) => {
        state.ReportsPrintLoading = true;
      })
      .addCase(retrieveReportsPrint.fulfilled, (state, action) => {
        if (action.payload) {
          state.ReportsPrintLoading = false;
          state.ReportsPrintData = action.payload as TReportsPrint[];
        }
      })
      .addCase(retrieveReportsPrint.rejected, (state, action) => {
        state.ReportsPrintLoading = false;
      });
  },
});


export const { reducer: ReportsPrintReducer } = ReportsPrintSlice;