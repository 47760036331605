import React from "react";

import { Card, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TProduct, TProducts } from "../../models/home/ProductsModel";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { THeaderProduct } from "../../models/NavigationModel";

type TProductCard = {
  cardButtonType: string;
  cardColorType: string;
  data: THeaderProduct;
  isVisible: boolean;
  onIsMenuShow?: React.Dispatch<React.SetStateAction<boolean>>;
  header?: boolean;
  hasLinks?: boolean;
};

const ProductCard = (props: TProductCard) => {
  const {
    cardButtonType,
    cardColorType,
    data,
    onIsMenuShow,
    header,
    isVisible,
    hasLinks = false,
  } = props;

  return (
    <Card className={`product-card ${cardColorType}`}>
      {hasLinks ? (
        <a
          href={`/product/${data.buttonUrl}`}
          onClick={() => {
            onIsMenuShow && onIsMenuShow(false);
          }}
        >
          <picture>
            <source media="(min-width:820px)" srcSet={data.image} />
            <img src={data.image} alt={data.title} className="img-fluid" />
          </picture>
        </a>
      ) : (
        <picture>
          <source media="(min-width:820px)" srcSet={data.image} />
          <img src={data.image} alt={data.title} className="img-fluid" />
        </picture>
      )}

      <Card.Body>
        {hasLinks ? (
          <a
            href={`/product/${data.buttonUrl}`}
            onClick={() => {
              onIsMenuShow && onIsMenuShow(false);
            }}
          >
            <h3 className="card-title">{data.title}</h3>
          </a>
        ) : (
          <h3 className="card-title">{data.title}</h3>
        )}
        <Card.Text
          dangerouslySetInnerHTML={{ __html: data.bodyHtml }}
        ></Card.Text>
        {isVisible && (
          <>
            {header ? (
              <a 
                // as={Link}
                // <Link
                className={`btn ${cardButtonType}`}
                href={`/product/${data.buttonUrl}`}
                onClick={() => {
                  onIsMenuShow && onIsMenuShow(false);
                }}
              >
                {data?.buttonText}
                <FontAwesomeIcon icon={solid("chevron-right")} />
              </a>
            ) : (
              <a
                className={`btn ${cardButtonType}`}
                href={`/product/${data.buttonUrl}`}
                onClick={() => {
                  onIsMenuShow && onIsMenuShow(false);
                }}
              >
                {data?.buttonText}
                <FontAwesomeIcon icon={solid("chevron-right")} />
              </a>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
