import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  TPageNotFoundInitialStateType } from '../../models/PageNotFoundModel';
import   { PageNotFoundService }   from '../../services/PageNotFoundService';


const initialState: TPageNotFoundInitialStateType = {
    PageNotFoundData: [],
    PageNotFoundLoading: true,
    PageNotFoundError: undefined,
  };

export const retrievePageNotFound = createAsyncThunk(
    "home/PageNotFound/retrieve",
    async () => {
      const response = await PageNotFoundService.getAllPageNotFound
      return [response.data];
    }
  );

const PageNotFoundSlice = createSlice({
  name: 'PageNotFound',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePageNotFound.pending, (state) => {
        state.PageNotFoundLoading = true;
      })
      .addCase(retrievePageNotFound.fulfilled, (state, action) => {
        if(action.payload){
          state.PageNotFoundLoading = false;
          state.PageNotFoundData = action.payload as string[];
        }
      })
      .addCase(retrievePageNotFound.rejected, (state, action) => {
        state.PageNotFoundLoading = false;
      })
  },
});


export default PageNotFoundSlice.reducer;