import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { THorseHealthSurveyByKitId, THorseHealthSurveyByKitIdInitialStateType, THorseHealthSurveyByKitIdResponse} from '../../../models/healthSurvey/HealthSurveyModel';
import HealthSurveyService from '../../../services/healthSurvey/HealthSurveyService';


const initialState: THorseHealthSurveyByKitIdInitialStateType = {
    GetHorseHealthSurveyByKitIdData: [],
    GetHorseHealthSurveyByKitIdLoading: true,
    GetHorseHealthSurveyByKitIdError: undefined,
};


export const getHorseHealthSurveyByKitId = createAsyncThunk(
    "customer-info/HorseHealthSurvey/addHorseHealthSurveyByKitId",
    async (kitId: string, { rejectWithValue }) => {
        try {
            const response = await HealthSurveyService.getHealthSurveyByKitId(kitId)
            const { data, status } = response
            return [{data, status}];
        } catch (error: any) {
            // If an error occurs, you can return the error data
            console.log(error.response.status);
            
            if (error.response.status) {
                window.location.href = '/page-not-found'
              }

            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const getHorseHealthSurveyByKitIdSlice = createSlice({
    name: 'customer-info/HorseHealthSurvey/getAllHorseHealthSurveyByKitId',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHorseHealthSurveyByKitId.pending, (state) => {
                state.GetHorseHealthSurveyByKitIdLoading = true;
            })
            .addCase(getHorseHealthSurveyByKitId.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetHorseHealthSurveyByKitIdLoading = false;
                    state.GetHorseHealthSurveyByKitIdData = action.payload as THorseHealthSurveyByKitIdResponse[];
                }
            })
            .addCase(getHorseHealthSurveyByKitId.rejected, (state, action) => {
                state.GetHorseHealthSurveyByKitIdLoading = false;
            });
    },
});


export default getHorseHealthSurveyByKitIdSlice.reducer;




