import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIErrors } from '../../../models/APIModel';
import { TAddsCard, TAddsCardInitialStateType, TMyHorse } from '../../../models/dashboard/MyHorsesModel';
import DashboardService from '../../../services/dashboard/MyHorsesService';


const initialState: TAddsCardInitialStateType = {
    GetAddsCardData: [],
    GetAddsCardLoading: true,
    GetAddsCardError: undefined,
};


export const GetAllAddsCard = createAsyncThunk(
    "customer-info/AddsCard/getAddsCard",
    async (path: string, { rejectWithValue }) => {
        try {
            const response = await DashboardService.AdsCard(path)
            return response.data;
        } catch (error: any) {
            // If an error occurs, you can return the error data
            if (!error.response) {
                throw error
            }
            return rejectWithValue([error.response.data]);
        }
    }
);


const GetAllAddsCardSlice = createSlice({
    name: 'customer-info/AddsCard/getAllAddsCard',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllAddsCard.pending, (state) => {
                state.GetAddsCardLoading = true;
            })
            .addCase(GetAllAddsCard.fulfilled, (state, action) => {
                if (action.payload) {
                    state.GetAddsCardLoading = false;
                    state.GetAddsCardData = action.payload as TAddsCard[];
                }
            })
            .addCase(GetAllAddsCard.rejected, (state, action) => {
                state.GetAddsCardLoading = false;
            });
    },
});


export default GetAllAddsCardSlice.reducer;




