import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProductsPOCInitialStateType, ProductsPOC } from '../../models/productsModel';
import ProductsPOCService from '../../services/productsService';

const initialState: ProductsPOCInitialStateType = {
    ProductsPOCData: [],
    ProductsPOCLoading: true,
    ProductsPOCError: undefined,
  };

  export const retrieveProductsPOC = createAsyncThunk(
    "ProductsPOC/retrieve",
    async () => {
      const response = await ProductsPOCService.getAllProductsPOCService()
      return [response.data];
    }
  );

const ProductsPOCPOCSlice = createSlice({
  name: 'ProductsPOC',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProductsPOC.pending, (state) => {
        state.ProductsPOCLoading = true;
      })
      .addCase(retrieveProductsPOC.fulfilled, (state, action) => {
        if(action.payload){
          state.ProductsPOCLoading = false;
          state.ProductsPOCData = action.payload as ProductsPOC[];
        }
      })
      .addCase(retrieveProductsPOC.rejected, (state, action) => {
        state.ProductsPOCLoading = false;
      });
  },
});

export default ProductsPOCPOCSlice.reducer;