import http from "../http-common";


class TermsAndConditionsService{
  getTermsAndConditions() {
    return http.get(`/PageConfiguration/TermsAndConditions`);
  }
}

export default new TermsAndConditionsService();
